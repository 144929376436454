import React from "react";
import styled from "styled-components";
import Headline from "../../globals/type/headline";

/**
 * Local Styles/JS
 */
import Wrapper from "../../globals/wrapper/wrapper";
import WrapperItem from "../../globals/wrapper/wrapper-item";
import Link from "../../helpers/link";
import MotionScrollTransform from "../../motion/motion-scroll-transform";

/**
 * Type
 */
import type { HomepageGalleryProps } from "./homepage-gallery.types";
import { Gallery } from "./homepage-gallery-strip";
import ClientOnly from "@components/helpers/client-only";

/**
 * Styled
 */
const HeadlineSection = styled(WrapperItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.sub-headline p {
    margin-bottom: 2rem;
  }
`;

/**
 * Styled
 */
const ScrollSection = styled(Wrapper)`
  padding: 12vw 0 10vw;

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    padding: 4vw 0 10vw;
  }

  [data-cy="motion-scroll-transform"] {
    grid-column-start: start-edge;
    grid-column-end: end-edge;
  }
`;

/**
 * HomepageScrollSection Component
 */
export default function HomepageGallery({
  content,
  items,
}: HomepageGalleryProps) {
  const ref = React.useRef(null);

  if (!content || !items) return null;

  const {
    headline,
    action_link,
    action_text,
    sub_headline,
    sticker_1,
    sticker_2,
  } = content as any;
  const { url } = action_link as any;

  return (
    <ScrollSection>
      {headline && (
        <HeadlineSection
          className="headline"
          colStart="start-rail"
          colEnd="end-rail"
        >
          <Headline text={headline} size="headline3" enableMotion={true} />
        </HeadlineSection>
      )}
      <MotionScrollTransform id="scroll-section" parentRef={ref}>
        {(transformProps: any) => (
          <ClientOnly>
            <Gallery
              {...transformProps}
              sticker_1={sticker_1}
              sticker_2={sticker_2}
              items={items}
            />
          </ClientOnly>
        )}
      </MotionScrollTransform>
      {sub_headline && (
        <HeadlineSection
          className="sub-headline"
          colStart="start-rail"
          colEnd="end-rail"
        >
          <Headline
            text={sub_headline}
            size="headline3"
            chLength={50}
            enableMotion={true}
          />
          {url && action_text && (
            <Link className="bodyLarge" to={url} hasArrow>
              {action_text}
            </Link>
          )}
        </HeadlineSection>
      )}
    </ScrollSection>
  );
}
