import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  LazyMotion,
  m,
  useMotionValue,
  useSpring,
  useTransform,
  domAnimation,
} from "framer-motion";

/**
 * Local Components
 */
import MotionScrollTransform from "../../motion/motion-scroll-transform";

/**
 * Local Styles/JS
 */
import Wrapper from "../../globals/wrapper/wrapper";
import WrapperItem, {
  WrapperItemProps,
} from "../../globals/wrapper/wrapper-item";
import Box from "../../motion/motion-box";
import Headline from "../../globals/type/headline";
import type { HeroContentProps, HeroProps } from "./hero.types";
import ImageBox from "../../motion/motion-box/image-box";
import { StaticImage } from "gatsby-plugin-image";
import Icon from "@components/svg/export/src/icon";
import { useWindowResizeListener } from "@components/motion/motion-scroll-transform/utils";
import useHoverCheck from "src/hooks/use-hover-check";

const PageHeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  overflow: visible;
  min-height: 60vh;
  max-width: 100vw;
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    min-height: 75vmax;
    height: 100vh;
  }

  @media (min-width: ${(props) => props.theme.size.desktop}) {
    min-height: 50vmax;
    height: 100vh;
  }

  .hero-headline {
    perspective: 1000px;

    @media (min-width: ${(props) => props.theme.size.laptop}) {
      justify-self: start;
      position: absolute;
      top: 2vw;
    }

    @media (min-width: ${(props) => props.theme.size.desktop}) {
      top: 4vw;
    }
  }

  .smile-shape-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .smile-shape-smile {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;

      svg {
        width: 44%;
        height: 44%;
      }
    }
  }

  .smile-shape {
    width: 50vmax;
    max-width: 100vw;

    @media (min-width: ${(props) => props.theme.size.laptop}) {
      width: 60vmax;
      max-width: 100vw;
      position: absolute;
    }

    @media (min-width: ${(props) => props.theme.size.desktop}) {
      width: 40vmax;
      max-width: 100vw;
      position: absolute;
    }
  }
`;

const Background = styled(m.div)`
  position: absolute;
  inset: 0;
  border-radius: 12vw;
  filter: blur(calc(var(--blur) * 1.5));
  z-index: -1;
  background-color: ${(props) => props.theme.colors.golden};
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    inset: 0 4vw;
    filter: blur(var(--blur));
  }

  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const SmileShapeElement = () => {
  const { width, height } = useWindowResizeListener();
  const canHover = useHoverCheck();
  const springConfig = { stiffness: 100, damping: 30, mass: 0.3 };
  const output = ["-2.5%", "2.5%"];
  const outputY = ["-3%", "3%"];
  const outputOuter = ["-2%", "2%"];
  const outputOuterY = ["-2.5%", "2.5%"];

  const smileX = useMotionValue(0.5);
  const smileXSpring = useSpring(smileX, springConfig);
  const x = useTransform(smileXSpring, [0, width], output);
  const xOuter = useTransform(smileXSpring, [0, width], outputOuter);

  const smileY = useMotionValue(0.5);
  const smileYSpring = useSpring(smileY, springConfig);
  const y = useTransform(smileYSpring, [0, height], outputY);
  const yOuter = useTransform(smileYSpring, [0, height], outputOuterY);

  useEffect(() => {
    if (typeof document !== undefined && typeof window !== undefined) {
      const handleMouseMove = (e: any) => {
        smileX.set(e.x);
        smileY.set(e.y);
      };
      window.addEventListener("mousemove", handleMouseMove);

      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, []);

  const outerStyle = () => {
    if (canHover) {
      return {
        x: xOuter,
        y: yOuter,
      };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  };

  const innerStyle = () => {
    if (canHover) {
      return {
        x: x,
        y: y,
      };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  };

  return (
    <Box
      className="smile-shape"
      transitionOverrides={{ delay: 1 }}
      hiddenStyles={{ y: "10%" }}
    >
      <m.div className="smile-shape-wrapper" style={outerStyle()}>
        <m.div className="smile-shape-smile" style={innerStyle()}>
          <Icon />
        </m.div>
        <StaticImage
          className="smile-shape-img"
          placeholder="blurred"
          loading="eager"
          src="https://images.prismic.io/happygdp/1e50b95e-ece2-40ed-90aa-c55341b9be93_Blank%3DTrue.png?auto=compress,format"
          alt=""
        />
      </m.div>
    </Box>
  );
};

const HeroContent = ({ content, yTransformOuter }: HeroContentProps) => {
  const ref = useRef();

  const {
    headline,
    background: { gatsbyImageData, alt },
  } = content;

  return (
    <m.div ref={ref}>
      <PageHeaderWrapper>
        <m.div
          className="hero-headline"
          style={
            {
              opacity: useTransform(yTransformOuter, [0, 0.15], [1, 0]),
              rotateX: useTransform(yTransformOuter, [0, 0.15], [0, 20]),
              y: useTransform(yTransformOuter, [0, 0.15], ["0rem", "-2rem"]),
            } as any
          }
        >
          <Headline
            as="h1"
            text={headline}
            size="headline3"
            enableMotion={true}
          />
        </m.div>
        <SmileShapeElement forwardRef={ref} />
      </PageHeaderWrapper>
      <Background
        initial={{ opacity: 0, "--blur": "10vw" } as any}
        animate={{ opacity: 1, "--blur": "6vw" } as any}
        transition={{ duration: 1, delay: 1.4 }}
        style={
          {
            borderRadius: useTransform(
              yTransformOuter,
              [0.25, 1],
              ["20vw", "50vw"]
            ),
          } as any
        }
      >
        <ImageBox image={gatsbyImageData} alt={alt || ""} />
      </Background>
    </m.div>
  );
};

/**
 * Hero Component
 */
export default function Hero({ content }: HeroProps) {
  const wrapperProps = {
    options: {
      colStart: "start-rail",
      colEnd: "end-rail",
    },
    mdOptions: {
      colStart: "start-edge",
      colEnd: "end-edge",
    },
  } as WrapperItemProps;

  return (
    <Wrapper>
      <WrapperItem {...wrapperProps}>
        <LazyMotion features={domAnimation}>
          {content && (
            <MotionScrollTransform id="page-header" smooth={false}>
              {(transformProps: any) => (
                <HeroContent content={content} {...transformProps} />
              )}
            </MotionScrollTransform>
          )}
        </LazyMotion>
      </WrapperItem>
    </Wrapper>
  );
}
