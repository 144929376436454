import { m } from "framer-motion";
import React, { FC } from "react";
import styled from "styled-components";
import Box from ".";
import VideoCover from "../../inline-video";

interface VideoBoxProps {
  video: {
    url: string;
  };
}

const getVideoContent = (video: object) => {
  const {
    document: {
      data: { mobile_video, desktop_video, ...rest },
    },
  } = video;

  if (mobile_video) {
    return { mobile_video, ...rest };
  }

  if (mobile_video && desktop_video) {
    return {
      mobile_video,
      desktop_video,
      ...rest,
    };
  }
};

const VideoBoxWrapper = styled(m.div)`
  .mobile-video,
  .desktop-video {
    position: absolute;
  }
  .mobile-video {
    visibility: visible;

    @media (min-width: ${(props) => props.theme.size.tablet}) {
      visibility: hidden;
    }
  }

  .desktop-video {
    visibility: hidden;

    @media (min-width: ${(props) => props.theme.size.tablet}) {
      visibility: visible;
    }
  }
`;

const VideoBox: FC<VideoBoxProps> = ({ video, ...rest }) => {
  const videoObject = getVideoContent(video);

  const {
    mobile_video,
    desktop_video,
    loop = false,
    autoplay = true,
    muted = true,
  } = videoObject;

  const videoOptions = {
    autoPlay: autoplay,
    playsInline: true,
    preload: "auto",
    muted: muted,
    loop: loop,
  };

  const desktopVideoAsset = desktop_video ? desktop_video : mobile_video;

  return (
    <VideoBoxWrapper {...rest}>
      {mobile_video && (
        <div className="mobile-video">
          <VideoCover
            videoOptions={videoOptions}
            source={mobile_video.map(({ source, type }: any) => ({
              src: source.url,
              type: type,
            }))}
            remeasureOnWindowResize
          />
        </div>
      )}
      {desktopVideoAsset && (
        <div className="desktop-video">
          <VideoCover
            videoOptions={videoOptions}
            source={desktopVideoAsset.map(({ source, type }: any) => ({
              src: source.url,
              type: type,
            }))}
            remeasureOnWindowResize
          />
        </div>
      )}
    </VideoBoxWrapper>
  );
};

export default VideoBox;
