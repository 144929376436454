import React from "react";
import styled from "styled-components";

/**
 * Local Styles/JS
 */
import Wrapper from "../../globals/wrapper/wrapper";
import MotionScrollTransform from "../../motion/motion-scroll-transform";
import DollarSmile from "./segment-components/dollar-smile-element";

/**
 * Type
 */
import type { HomepageScrollSectionProps } from "./homepage-scroll-section.types";
import { m, motion, MotionValue, useTransform } from "framer-motion";
import Headline from "../../globals/type/headline";
import { makeNumberElement } from "./segment-components/number-segment";
import { Sentence } from "./segment-components/sentence-segement";
import { log } from "console";

/**
 * Styled
 */
const ScrollSection = styled<any>(Wrapper)`
  height: ${({ sections }) => sections * 100}vh;
  width: 100vw;

  [data-cy="motion-scroll-transform"] {
    grid-column-start: start-edge;
    grid-column-end: end-edge;
  }

  .child-snapper {
    position: absolute;
    height: 2rem;
    width: 2rem;
    z-index: 10;
    scroll-snap-stop: always;
    background: transparent;

    /* &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      width: "100%";
      height: 0.1rem;
      z-index: 9999;
    } // for debugging */

    &:before {
      top: -33%;
      background: red;
    }

    &:after {
      bottom: -33%;
      background: magenta;
    }

    &.segment-1 {
      top: 0;
      height: 33.3333333333%;
      scroll-snap-align: center;
      /* background: orange; // for dedugging */
    }

    &.segment-2 {
      bottom: 33.3333333333%;
      height: 33.3333333333%;
      scroll-snap-align: center;
      /* scroll-margin: 33% 0; */
      /* background: yellow; // for dedugging */
    }

    &.segment-3 {
      bottom: 0%;
      height: 33.3333333333%;
      scroll-snap-align: center;
      /* scroll-margin: 33% 0; */
      /* background: blue; // for dedugging */
    }
  }
`;

const StickySectionWrapper = styled(m.div)`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: white;
  transform-origin: center top;

  .gdp-number {
    display: inline-flex;
    height: 1.1em;
    position: relative;

    &:before,
    &:after {
      content: "";
      width: 2em;
      left: 0.5em;
      height: 0.18em;
      position: absolute;
      z-index: 1;
    }

    &:after {
      background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #fff 100%);
      top: -0.125rem;
    }

    &:before {
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        #fff 100%
      );

      bottom: -0.125rem;
    }
  }

  .asterisk {
    font-size: 0.3em;
    position: absolute;
    right: 0;
    top: -0.5em;
  }

  strong.citation {
    position: absolute;
    right: 4vw;
    bottom: 4vw;
    margin-bottom: 0;
  }

  .gdp-text {
    margin-left: 0.2em;
  }

  .gdp-text,
  .asterisk,
  .citation,
  .number-outer-wrap,
  .gdp-sentence {
    opacity: var(--group-opacity);
  }
`;

const DotContainer = styled.div`
  --dot-size: 0.75rem;
  --button-size: 1.5rem;

  border-radius: var(--button-size);
  border: solid 0.1rem rgba(0, 0, 0, 0.1);
  background: white;
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  /* left: 4vw; */
  bottom: 4rem;
  z-index: 20;

  @media (min-width: ${(props) => props.theme.size.tablet}) {
    /* padding: 8vw 0; */
    right: 4vw;
    left: unset;
    bottom: unset;
  }
`;

const DotElement = styled.button`
  height: var(--button-size);
  width: var(--button-size);
  border-radius: var(--button-size);
  margin: 0.1rem 0;
  appearance: none;
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &:hover {
    &:after {
      opacity: 0.1;
    }
  }

  &:after {
    content: "";

    position: absolute;
    height: var(--dot-size);
    width: var(--dot-size);
    border-radius: var(--dot-size);
    background: ${({ theme }) => theme.colors.black};
    transform: scale(0.95);
    opacity: 0.2;
    transition: opacity ${(props) => props.theme.animation.duration[100].css}
      ${(props) => props.theme.animation.timingFunction.css};
  }
`;

const DotLane = styled.div`
  position: absolute;
  top: calc(var(--dot-size) / 2 + 1px);
  left: calc(var(--dot-size) / 2);
  height: calc(100% - var(--dot-size));
  width: var(--dot-size);
  pointer-events: none;
`;

const DotActive = styled(motion.div)`
  position: absolute;
  height: var(--dot-size);
  width: var(--dot-size);
  border-radius: var(--dot-size);
  background: ${({ theme }) => theme.colors.black};
  pointer-events: none;
`;

const scrollToPosition = (position: any) => {
  window.scrollTo({
    top: position,
    behavior: "smooth",
  });
};

/**
 * IndicatorDots Fragment
 */
function IndicatorDots({ transform, middleRange }: any) {
  const y = useTransform(
    transform,
    [0.25, 0.5, 0.75],
    ["0", "1.7rem", "3.4rem"]
  );

  return (
    <DotContainer>
      <DotLane>
        <DotActive style={{ y }} />
      </DotLane>
      {middleRange.map(({ pixel }: any) => {
        return <DotElement onClick={() => scrollToPosition(pixel[1])} />;
      })}
    </DotContainer>
  );
}

/**
 * StickySection
 */
function StickySection({
  content,
  yTransformOuter,
  sectionHeight,
  sectionTopDistance,
}: any) {
  const { citation, gdp_number = 23, gdp_text = "trillion" } = content;

  const segments = [
    {
      name: "number",
      input: [0, 0.25],
    },
    {
      name: "sentence",
      input: [0.26, 0.4166666667], // + 0.1666666667
    },
    {
      name: "smile",
      input: [0.4166666668, 0.5833333334],
    },
    {
      name: "3D smile",
      input: [0.5833333335, 0.75],
    },
    {
      name: "end",
      input: [0.76, 1],
    },
  ];

  const group_range = [
    [0, 0.1, 0.11, 0.24],
    [...segments[1].input, ...segments[2].input],
    [...segments[2].input, ...segments[3].input],
  ];

  const getMedian = (a: number, b: number) => (a + b) / 2;

  const getPixelPosition = (unit: any) => {
    return unit * sectionHeight + sectionTopDistance;
  };

  const getMiddleOfArray = (arr: any) => {
    return arr.map((item: any) => {
      const diff = 0.02;
      const unitOne = item[1] - diff;
      const unitMiddle = getMedian(item[1], item[2]);
      const unitTwo = item[2] + diff;
      return {
        progress: [unitOne, unitMiddle, unitTwo],
        pixel: [
          getPixelPosition(unitOne),
          getPixelPosition(unitMiddle),
          getPixelPosition(unitTwo),
        ],
      };
    });
  };

  const middleRange = getMiddleOfArray(group_range);

  const numberSegment = segments[0].input;
  const sentenceSegment = segments[1].input;
  const smileSegment = segments[2].input;
  const smileOffsetSegment = [segments[2].input[0] + 0.1, segments[2].input[1]];
  const smileFocusSegment = segments[3].input;

  const segmentGroup = [...numberSegment, ...smileFocusSegment];

  return (
    <StickySectionWrapper
      className="scroll-block"
      style={
        {
          "--group-opacity": useTransform(
            yTransformOuter,
            smileOffsetSegment,
            [1, 0]
          ),
        } as any
      }
    >
      <IndicatorDots
        transform={yTransformOuter}
        segments={segments}
        sectionTopDistance={sectionTopDistance}
        sectionHeight={sectionHeight}
        middleRange={middleRange}
      />
      <m.div
        style={{
          y: useTransform(yTransformOuter, numberSegment, ["-20%", "0%"]),
          x: useTransform(yTransformOuter, smileFocusSegment, ["0%", "52%"]),
          scale: useTransform(yTransformOuter, segmentGroup, [2, 1, 1, 1.2]),
        }}
      >
        <Headline size="display1" className="gdp-number">
          <DollarSmile
            smileSegment={smileSegment}
            smileFocusSegment={smileFocusSegment}
            transform={yTransformOuter}
          />
          {makeNumberElement({
            numberSegment: numberSegment,
            number: gdp_number,
            transform: yTransformOuter,
          })}
          <span className="gdp-text">{gdp_text}</span>
          {citation && <span className="asterisk">*</span>}
        </Headline>
      </m.div>
      {citation && <m.strong className="citation">{citation}</m.strong>}
      <Sentence
        transform={yTransformOuter}
        sentenceSegment={sentenceSegment}
        smileFocusSegment={smileFocusSegment}
      />
    </StickySectionWrapper>
  );
}

/**
 * HomepageScrollSection Component
 */
export default function HomepageScrollSection({
  content,
}: HomepageScrollSectionProps) {
  const ref = React.useRef(null);

  if (!content) return null;

  return (
    <>
      <ScrollSection key="scroll-section" sections={3}>
        <MotionScrollTransform id="scroll-section" parentRef={ref}>
          {(transformProps: any) => (
            <StickySection {...transformProps} content={content} />
          )}
        </MotionScrollTransform>

        <div className="child-snapper segment-1" />
        <div className="child-snapper segment-2" />
        <div className="child-snapper segment-3" />
      </ScrollSection>
    </>
  );
}

const ScrollSnap = styled.div``;
