import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Lottie from "react-lottie-player";
import { m, useTransform } from "framer-motion";
import DollarSmileData from "../lottie-data/data_60fps.json";
import { StaticImage } from "gatsby-plugin-image";

const DollarSmileContainer = styled(m.span)`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(0.05em, 0.1em, 0);
  height: 0.9em;
  z-index: 3;

  > div,
  svg {
    height: 100%;
    width: 0.65em;
    z-index: 3;
  }

  &:after {
    content: "";
    width: var(--lottie-after-width, 0%);
    opacity: var(--lottie-after-opacity, 0);
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #fff 100%);
    top: -0.125rem;
    left: 0.6em;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
`;

const SmileImageWrapper = styled(m.span)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  will-change: transform, opacity;

  .smile-shape {
    position: absolute;
    width: 2em;
    filter: blur(Var(--glow-blur));
  }

  .glow-shape {
    filter: blur(5vw);
    position: absolute;
    width: 100vmin;
    height: 100vmin;
    z-index: -1;
    opacity: var(--glow-opacity);
    transform: scale(var(--glow-scale));
    will-change: transform, opacity;

    @media (min-width: ${(props) => props.theme.size.tablet}) {
      width: 95vmin;
      height: 95vmin;
    }

    > div {
      height: 100%;
    }

    img {
      object-fit: contain;
    }
  }
`;

export function DollarSmile({
  smileSegment,
  smileFocusSegment,
  transform,
}: any) {
  const [goTo, setGoTo] = useState(0);

  const frameRange = [0, 94];
  const goToTransform = useTransform(transform, smileSegment, frameRange);

  useEffect(() => {
    const unsubscribeOuterScrollProgress = transform.onChange(() =>
      setGoTo(goToTransform.get())
    );

    return () => unsubscribeOuterScrollProgress();
  }, [transform]);

  return (
    <DollarSmileContainer
      id="smile-container"
      style={
        {
          "--lottie-after-width": useTransform(transform, smileFocusSegment, [
            "0em",
            "10em",
          ]),
          "--lottie-after-opacity": useTransform(
            transform,
            smileFocusSegment,
            [0.5, 1]
          ),
          x: "0.05em",
          y: "0.1em",
          scale: useTransform(transform, [0.7, 0.75], [1, 1.2]),
        } as any
      }
    >
      <Lottie goTo={goTo} animationData={DollarSmileData} />
      <SmileImageWrapper
        style={
          {
            opacity: useTransform(transform, [0.55, 0.7], [0, 1]),
            "--glow-opacity": useTransform(transform, [0.7, 0.75], [0, 0.8]),
            "--glow-scale": useTransform(transform, [0.725, 0.75], [0.5, 0.7]),
            "--glow-blur": useTransform(
              transform,
              [0.55, 0.75],
              ["5vw", "0vw"]
            ),
          } as any
        }
      >
        <StaticImage
          className="smile-shape"
          src="https://images.prismic.io/happygdp/1e50b95e-ece2-40ed-90aa-c55341b9be93_Blank%3DTrue.png?auto=compress,format"
          alt=""
        />
        <StaticImage
          className="glow-shape"
          src="https://images.prismic.io/happygdp/b5fac0c3-3ed2-4e6d-bc2b-664ddc1c53bd_glow.png?auto=compress,format"
          alt=""
        />
      </SmileImageWrapper>
    </DollarSmileContainer>
  );
}

export default DollarSmile;
