export const showFirstNumberInNumber = (number: number | string) => {
  const firstNumber = number.toString().split("")[0];
  const secondNumber = number.toString().split("")[1];
  return [firstNumber, secondNumber];
};

export const countBackwardsToTen = (number: number | string) => {
  const numberArray = [];
  for (let i = number; i > 10; i--) {
    const numberToString = i.toString();
    const numberSplit = numberToString.split("");
    const object = [numberSplit[0], numberSplit[1]];
    numberArray.push(object);
  }
  return numberArray;
};

export const createArrayOfNumbersCountingBackwards = (
  number: number | string
) => {
  const numberArray = [];
  for (let i = number; i > -1; i--) {
    const numberToString = i.toString();
    numberArray.push(numberToString);
  }
  return numberArray;
};

export const makeSubSegments = (segment: number[], count: number) => {
  const diff = segment[1] - segment[0];
  const incriment = diff / count;

  const subSegments = [];
  for (let i = 0; i <= count; i++) {
    subSegments.push(segment[0] + incriment * i);
  }

  return subSegments;
};
