import React from "react";
import styled from "styled-components";
import { m, useTransform } from "framer-motion";
import {
  countBackwardsToTen,
  showFirstNumberInNumber,
} from "../homepage-scroll-section.utils";

/**
 * NUMBER styled
 */
const NumberWrap = styled(m.span)`
  display: inline-flex;
  flex-direction: column;
  justify-content: end;
  height: 1.1em;
  width: 0.58em;
  overflow: hidden;
  position: relative;
  font-feature-settings: "tnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss03" on, "ss04" on;

  .number-outer-wrap {
    display: inline-flex;
    flex-direction: column;
  }
`;

const generateNumberArray = (number: number) => {
  const num = countBackwardsToTen(number);
  return num;
};

export const makeNumberElement = ({
  numberSegment,
  number,
  transform,
}: any) => {
  const numberArray = showFirstNumberInNumber(number);

  const numArray = generateNumberArray(number);

  const numbersArray = [
    {
      target: numberArray[0],
      list: numArray,
    },
    {
      target: numberArray[1],
      list: numArray,
    },
  ];

  const mapNumbers = numbersArray.map((num: any, index: number) => (
    <Number
      key={num.target}
      target={num.target}
      list={num.list}
      index={index}
      transform={transform}
      numberSegment={numberSegment}
    />
  ));
  return mapNumbers;
};
const Number = ({ index, target, list, transform, numberSegment }: any) => {
  if (index === 0) {
    list = [[2], [1], [0]];
  }

  const genEndNumber = `${(list.length - 1) * 1.1}em`;
  const y = useTransform(transform, numberSegment, ["0em", genEndNumber]);

  return (
    <NumberWrap>
      <m.span
        className="number-outer-wrap"
        style={{
          y,
        }}
      >
        {list?.map((number: any) => (
          <span>{number[index]}</span>
        ))}
      </m.span>
    </NumberWrap>
  );
};
