import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { m, useMotionValue, useSpring, useTransform } from "framer-motion";

import WrapperItem from "../../globals/wrapper/wrapper-item";
import VideoBox from "../../motion/motion-box/video-box";
import { GalleryItem } from "./homepage-gallery-strip-item";

import type { HomepageGalleryStripProps } from "./homepage-gallery.types";
import { useWindowResizeListener } from "@components/motion/motion-scroll-transform/utils";
import useHoverCheck from "src/hooks/use-hover-check";

/**
 * Styled
 */
const GallerySection = styled(WrapperItem)`
  display: inline-flex;
  max-width: 100vw;
  overflow: hidden;
  justify-content: center;
  margin-top: 4vw;
  margin-bottom: 4vw;

  &.no-hover {
    overflow: scroll;
    padding-bottom: 3vw;
  }

  .strip {
    display: inline-flex;
  }
`;
const StickerVideo = styled(VideoBox)<any>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  --size: 35vw;
  width: var(--size);
  height: var(--size);

  @media (min-width: ${(props) => props.theme.size.tablet}) {
    --size: 15vw;
  }

  &.sticker-1 {
    left: calc(74vw - calc(var(--size) / 2));
    top: calc(30% - calc(var(--size) / 2));

    @media (min-width: ${(props) => props.theme.size.tablet}) {
      left: calc(44vw - calc(var(--size) / 2));
      top: calc(30% - calc(var(--size) / 2));
    }
  }

  &.sticker-2 {
    left: calc(140vw - calc(var(--size) / 2));
    top: calc(65% - calc(var(--size) / 2));

    @media (min-width: ${(props) => props.theme.size.tablet}) {
      left: calc(90vw - calc(var(--size) / 2));
      top: calc(65% - calc(var(--size) / 2));
    }
  }
`;

/**
 * HomepageGallery Component
 */
export function Gallery({
  items,
  sticker_1,
  sticker_2,
}: HomepageGalleryStripProps) {
  const { width } = useWindowResizeListener();
  const canHover = useHoverCheck();

  const stripRef = useRef();
  const stripPosition = useMotionValue(1);
  const springConfig = { stiffness: 100, damping: 30, mass: 0.3 };
  const xSpring = useSpring(stripPosition, springConfig);
  const x = useTransform(xSpring, [0, width], ["20vw", "calc(-20vw)"]);

  useEffect(() => {
    if (!canHover) {
      stripPosition.set(0);
    }
  }, [canHover]);

  useEffect(() => {
    if (
      canHover &&
      stripRef &&
      typeof document !== undefined &&
      typeof window !== undefined
    ) {
      const handleMouseMove = (e: any) => {
        stripPosition.set(e.x);
      };
      stripRef?.current.addEventListener("mousemove", handleMouseMove);

      return () => {
        stripRef?.current.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [stripRef, canHover]);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  return (
    <GallerySection
      className={`${canHover ? "hover" : "no-hover"}`}
      colStart="start-edge"
      colEnd="end-edge"
    >
      <m.div
        ref={stripRef}
        className={`strip`}
        variants={container}
        initial="hidden"
        whileInView="show"
        style={{
          x,
        }}
        viewport={{
          once: true,
        }}
      >
        {items.map((item: any) => (
          <GalleryItem {...item?.investment_gallery?.document?.data} />
        ))}
        <StickerVideo className="sticker-1" video={sticker_1} />
        <StickerVideo className="sticker-2" video={sticker_2} />
      </m.div>
    </GallerySection>
  );
}
