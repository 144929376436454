import React from "react";
import { graphql } from "gatsby";

import { Hero } from "../components/hero";
import { getSlice } from "../functions/util";
import { HomepageGallery } from "../components/homepage-gallery";
import HomepageScrollSection from "../components/homepage-scroll-section/src/homepage-scroll-section";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
function HomeTemplate({
  data: {
    prismicHomepage: {
      data: { body },
    },
  },
}) {
  const heroSlice = getSlice(body, "hero");
  const gallerySlice = getSlice(body, "investment_gallery");
  const scrollSlice = getSlice(body, "scroll_section");

  return (
    <>
      {heroSlice && <Hero content={heroSlice?.primary} />}
      {gallerySlice && (
        <HomepageGallery
          content={gallerySlice?.primary}
          items={gallerySlice?.items}
        />
      )}
      {scrollSlice && <HomepageScrollSection content={scrollSlice?.primary} />}
    </>
  );
}
/**
 * pageQuery
 */
export const pageQuery = graphql`
  query HomePageTemplate {
    prismicHomepage {
      id
      data {
        description
        title
        body {
          ... on PrismicHomepageDataBodyHero {
            id
            primary {
              background {
                url
                alt
                gatsbyImageData(srcSetMaxWidth: 800, imgixParams: { q: 1 })
              }
              headline
            }
            slice_type
          }
          ... on PrismicHomepageDataBodyInvestmentGallery {
            id
            slice_type
            primary {
              action_text
              headline
              sub_headline
              action_link {
                url
                uid
                target
              }
              sticker_1 {
                document {
                  ... on PrismicVideo {
                    id
                    data {
                      autoplay
                      loop
                      muted
                      alt_text
                      desktop_video {
                        type
                        source {
                          uid
                        }
                      }
                      mobile_video {
                        source {
                          url
                        }
                        type
                      }
                    }
                  }
                }
              }
              sticker_2 {
                document {
                  ... on PrismicVideo {
                    id
                    data {
                      autoplay
                      loop
                      muted
                      alt_text
                      desktop_video {
                        type
                        source {
                          uid
                        }
                      }
                      mobile_video {
                        source {
                          url
                        }
                        type
                      }
                    }
                  }
                }
              }
            }
            items {
              investment_gallery {
                document {
                  ... on PrismicInvestment {
                    id
                    data {
                      logo_asset {
                        alt
                        copyright
                        url
                        gatsbyImageData(
                          srcSetMaxWidth: 100
                          imgixParams: { q: 1 }
                        )
                      }
                      investment_type
                      feature_image {
                        alt
                        copyright
                        url
                        gatsbyImageData(
                          srcSetMaxWidth: 800
                          imgixParams: { q: 1 }
                        )
                      }
                      category
                      brand_url {
                        id
                      }
                      brand_sentence
                      brand_name {
                        text
                        html
                      }
                      brand_url {
                        url
                        target
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageDataBodyScrollSection {
            id
            slice_type
            primary {
              citation
              gdp_number
              gdp_text
            }
          }
        }
        share_image {
          url
        }
      }
    }
  }
`;

export default HomeTemplate;
