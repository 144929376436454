import React from "react";
import styled from "styled-components";
import { getRandomArbitrary } from "../../../functions/util";
import { m } from "framer-motion";
import ImageBox from "../../motion/motion-box/image-box";

const GalleryItemWrap = styled(m.a)`
  --width: 33vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: var(--width);
  margin: 0 1vw;

  @media (min-width: ${(props) => props.theme.size.tablet}) {
    --width: 22vw;
  }

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    --width: 20vw;
  }

  &:hover {
    cursor: pointer;

    .feature_image .gatsby-image-wrapper {
      border-radius: calc(var(--width) * 0.45);
    }

    .feature_image img {
      transform: scale(1);
      filter: none;
    }
  }

  .feature_image {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 9 / 18;

    .gatsby-image-wrapper {
      height: var(--rando-height);
      border-radius: calc(var(--width) * 0.5);
      overflow: hidden;
      transition: 0.75s ${({ theme }) => theme.animation.timingFunction.css};
      transition-properties: border-radius;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
    }

    img {
      filter: grayscale(100%) contrast(0.8);
      transform: scale(1.1);
      transition: 0.5s ${({ theme }) => theme.animation.timingFunction.css};
      transition-properties: transform, filter;
    }
  }

  .logo_asset {
    margin-top: 4vw;

    @media (min-width: ${(props) => props.theme.size.tablet}) {
      margin-top: 2vw;
    }
  }

  .logo_asset img {
    /* width: 5rem; */
    object-fit: contain !important;

    @media (min-width: ${(props) => props.theme.size.tablet}) {
      /* width: 8rem; */
    }
  }
`;

const LogoBox = styled(ImageBox)`
  img {
    width: calc(var(--img-width) * 0.3);
    max-width: calc(var(--img-width) * 0.3);
    height: calc(var(--img-height) * 0.3);
    max-height: calc(var(--img-height) * 0.3);

    @media (min-width: ${(props) => props.theme.size.tablet}) {
      width: calc(var(--img-width) * 0.5);
      max-width: calc(var(--img-width) * 0.5);
      height: calc(var(--img-height) * 0.5);
      max-height: calc(var(--img-height) * 0.5);
    }

    @media (min-width: ${(props) => props.theme.size.laptopLg}) {
      width: calc(var(--img-width) * 0.75);
      max-width: calc(var(--img-width) * 0.75);
      height: calc(var(--img-height) * 0.75);
      max-height: calc(var(--img-height) * 0.75);
    }
  }
`;

export function GalleryItem({
  brand_name,
  brand_url,
  logo_asset,
  feature_image,
}: any) {
  if (!feature_image && !logo_asset) return null;

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    <GalleryItemWrap
      variants={item}
      href={brand_url.url}
      target={brand_url.target}
    >
      <ImageBox
        className="feature_image"
        image={feature_image?.gatsbyImageData}
        alt={feature_image.alt || ""}
        style={
          {
            "--rando-height": `${getRandomArbitrary(75, 100)}%`,
          } as any
        }
      />
      <LogoBox
        className="logo_asset"
        image={logo_asset?.gatsbyImageData}
        alt={brand_name?.text}
        hiddenStyles={{ y: 0, scale: 0.9 }}
        style={
          {
            "--img-width": `${logo_asset?.gatsbyImageData?.width}px`,
            "--img-height": `${logo_asset?.gatsbyImageData?.height}px`,
          } as any
        }
      />
    </GalleryItemWrap>
  );
}
