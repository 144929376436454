import React from "react";
import styled from "styled-components";
import { m, useTransform } from "framer-motion";
import { makeSubSegments } from "../homepage-scroll-section.utils";
import Headline from "../../../globals/type/headline";

const SentenceStyle = styled(Headline)`
  span {
    display: inline;
  }
`;
export const Sentence = ({ transform, sentenceSegment }: any) => {
  const segments = makeSubSegments(sentenceSegment, 4);
  const yStart = 10;

  const subSeg1 = {
    opacity: useTransform(transform, segments, [0, 1, 1, 1, 1]),
    y: useTransform(transform, segments, [yStart, 0, 0, 0, 0]),
  } as any;

  const subSeg2 = {
    "--cross-out-width": useTransform(transform, segments, [
      "0%",
      "100%",
      "100%",
      "100%",
      "100%",
    ]),
    opacity: useTransform(transform, segments, [0, 0, 1, 1, 1]),
    y: useTransform(transform, segments, [yStart, yStart, 0, 0, 0]),
  };

  const subSeg3 = {
    opacity: useTransform(transform, segments, [0, 0, 0, 1, 1]),
    y: useTransform(transform, segments, [yStart, yStart, yStart, 0, 0]),
  } as any;

  const subSeg4 = {
    opacity: useTransform(transform, segments, [0, 0, 0, 0, 1]),
    y: useTransform(transform, segments, [yStart, yStart, yStart, yStart, 0]),
  } as any;

  return (
    <SentenceStyle size="headline4" className="gdp-sentence">
      <m.span style={subSeg1}>turning the</m.span>{" "}
      <m.span style={subSeg2}>dollar economy</m.span>{" "}
      <m.span style={subSeg3}>into the</m.span>{" "}
      <m.span style={subSeg4}>smile economy.</m.span>
    </SentenceStyle>
  );
};
